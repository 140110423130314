import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const Env = window.cccisd.env;

const NavBar = ({ className, noLogoLink }) => {
    const wpUrl = Env.wpUrl || 'https://www.helpwantedprevention.org';

    const publicLinks = [
        {
            label: 'Home',
            url: wpUrl,
            highlight: [],
        },
        {
            label: 'Privacy',
            url: wpUrl + '/privacy/index.html',
            highlight: [],
        },
        /* Temporarily hidden at client's request */
        // {
        //     label: 'Course',
        //     url: wpUrl + '/course/index.html',
        //     highlight: [],
        // },
        {
            label: 'Resources',
            url: '/resources',
            highlight: [],
        },
        {
            label: 'About',
            url: '/about',
            highlight: [],
        },
        {
            label: 'Contact',
            url: '/contact',
            highlight: [],
        },
    ];

    let primaryNavLinks = [];
    try {
        if (Fortress.auth()) {
            primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
        } else {
            primaryNavLinks = publicLinks;
        }
    } catch (e) {
        // meh, leave it empty
    }

    const isLearner = Fortress.user.acting.role.handle === 'learner';
    let logoLink = 'https://www.helpwantedprevention.org';
    if (isLearner) {
        logoLink = '/study';
    } else if (noLogoLink) {
        logoLink = null;
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={`${style.logo} ${isLearner ? style.learner : ''}`}
            wrapperClassName={`${style.wrapper1} ${style.wrapper2} ${Fortress.auth() ? '' : style.loggedOut}`} // need higher specificy for link color
            primaryNavLinks={primaryNavLinks}
            logoLink={logoLink}
            showAlertAction={false}
            showHomeAction={false}
            showUserNav={Fortress.auth()}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
