import React from 'react';
import style from './style.css';

export default function PresenterBox({ image, blurb }) {
    return (
        <div className={style.presenterBox}>
            <div className={style.image}>
                <img src={image} alt="mugshot" />
            </div>
            <p>{blurb}</p>
        </div>
    );
}
