import React from 'react';
import _get from 'lodash/get';
import { getColor } from 'cccisd-color-picker';
import PlatformFooter from 'cccisd-footer';
import style from './style.css';
import logo from './footer.png';

const Appdefs = window.cccisd.appDefs;

const Footer = props => {
    const backgroundColor = getColor(_get(Appdefs, 'app.footer.backgroundColor', 'rgb(248, 248, 248)'));

    return (
        <>
            <div style={{ backgroundColor }}>
                <div className={`${props.className} ${style.logoContainer}`}>
                    <img src={logo} alt="" className={style.logos} />
                </div>
            </div>
            <PlatformFooter {...props} />
        </>
    );
};

Footer.defaultProps = {
    className: 'container',
};

export default Footer;
