import React, { useState } from 'react';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdTextarea } from 'cccisd-formik';
import IconCheck from 'cccisd-icons/checkmark4';
import IconWarn from 'cccisd-icons/warning';
import notification from 'cccisd-notification';
import { Link } from 'cccisd-react-router';
import validator from 'cccisd-validator';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const Contact = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFail, setIsFail] = useState(false);

    async function handleSubmit(vals) {
        setIsSuccess(false);
        setIsFail(false);

        let resp;
        try {
            resp = await axios.post(Boilerplate.route('api.app.contactForm'), vals);
        } catch (e) {
            console.error(e);
            setIsFail(true);
            return;
        }

        const responseSuccess = resp?.data?.status === 'success';
        if (responseSuccess) {
            setIsSuccess(true);
            setIsFail(false);
            notification({ message: 'Message sent!', type: 'success' });
        } else {
            setIsSuccess(false);
            setIsFail(true);
            notification({ message: 'Message not sent', type: 'danger' });
        }
    }

    function handleValidate(vals) {
        const errors = {};
        if (!vals.feedbackType) {
            errors.feedbackType = 'Required';
        }

        const emailVal = (vals.contactEmail || '').trim();
        if (!emailVal) {
            errors.contactEmail = 'Required';
        } else if (validator(emailVal, { types: ['email'] })?.status === 'fail') {
            errors.contactEmail = 'Must be email address';
        }

        if (!vals.message) {
            errors.message = 'Required';
        }

        return errors;
    }

    function getInitialValues() {
        return {
            feedbackType: 'moreInfo',
        };
    }

    function renderRequiredLabel(label) {
        return (
            <span>
                {label}
                <span className="text-danger">*</span>
            </span>
        );
    }

    return (
        <div className={style.page}>
            <h2 className={style.title}>Contact Us</h2>
            <div className={style.titleBorder} />
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className={style.formColumn}>
                            <p>
                                If you have questions or would like to provide feedback, please send us a message below.
                            </p>
                            <p>
                                Responses to this site are not continuously monitored, and we are unable to provide
                                counseling or referral services. See our <Link to="/resources">Resources</Link> page for
                                additional information.
                            </p>
                            <p>
                                If you are at risk of offending a child, contact&nbsp;
                                <a target="_blank" href="https://stopitnow.org" rel="noopener noreferrer">
                                    Stop It Now
                                </a>
                                .
                            </p>
                            <p>If you are in crisis, please seek help immediately:</p>
                            <ul>
                                <li>Call 1-800-273-TALK (8255) to reach a 24-hour crisis center,</li>
                                <li>Text MHA to 741741, or</li>
                                <li>Go to the nearest emergency room</li>
                            </ul>
                            <Formik
                                initialValues={getInitialValues()}
                                onSubmit={handleSubmit}
                                validate={handleValidate}
                            >
                                {formikBag => (
                                    <Form className={style.form}>
                                        <Field
                                            name="feedbackType"
                                            label={renderRequiredLabel('Feedback Type')}
                                            component={CccisdSelect}
                                            options={[
                                                { value: 'moreInfo', label: 'More Information' },
                                                { value: 'technicalSupport', label: 'Technical Support' },
                                            ]}
                                        />
                                        <Field
                                            name="contactEmail"
                                            label={renderRequiredLabel('Contact Email')}
                                            component={CccisdInput}
                                        />
                                        <Field
                                            name="message"
                                            label={renderRequiredLabel('Message')}
                                            component={CccisdTextarea}
                                            rows="8"
                                        />
                                        <Button
                                            title="Submit"
                                            className="btn btn-primary"
                                            onClick={formikBag.handleSubmit}
                                            isLoading={formikBag.isSubmitting}
                                        />
                                    </Form>
                                )}
                            </Formik>
                            {isSuccess && (
                                <p className={'text-success ' + style.feedbackMessage}>
                                    <IconCheck spaceRight />
                                    Message submitted successfully!
                                </p>
                            )}
                            {isFail && (
                                <p className={'text-danger ' + style.feedbackMessage}>
                                    <IconWarn spaceRight />
                                    Message failed to send
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-1" />
                    <div className="col-xs-12 col-md-5">
                        <div className={style.contactInfo}>
                            <h4>Contact Info</h4>
                            <address className={style.address}>
                                <ul>
                                    <li>
                                        <b>Moore Center for the Prevention of Child Sexual Abuse</b>
                                    </li>
                                    <li>Johns Hopkins Bloomberg School of Public Health</li>
                                    <li>415 N. Washington Street</li>
                                    <li>Office 523</li>
                                    <li>Baltimore, MD 21231</li>
                                </ul>
                            </address>
                            <p className={style.phone}>
                                <b>Phone:</b>&nbsp;<a href="tel:4106144101">410-614-4101</a>
                            </p>
                            <div className={style.technicalContact}>
                                <h4>Technical Support</h4>
                                <p>
                                    <b>E-mail:</b>&nbsp;
                                    <a href="mailto:support@3cisd.com">support@3cisd.com</a>
                                </p>
                                <p>
                                    <b>Phone:</b>&nbsp;<a href="tel:8884735633">888-473-5633</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
