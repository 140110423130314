import React from 'react';
import style from './style.css';
import PresenterBox from './PresenterBox';
import presenter1 from './presenter1.jpg';
import presenter2 from './presenter2.jpg';
import presenter3 from './presenter3.jpg';

function About() {
    return (
        <div className={style.page}>
            <div className={style.section}>
                <h2 className={style.title}>The Help Wanted Prevention Project</h2>
                <div className={style.titleBorder} />
                <p>
                    The <i>Help Wanted Prevention Project</i> is an online course to provide help to adolescents and
                    young adults attracted to younger children. The course is supported by the Moore Center for the
                    Prevention of Child Sexual Abuse, Johns Hopkins Bloomberg School of Public Health and by RALIANCE.
                    This work was developed by the faculty and staff at the Moore Center in collaboration with other
                    national and international experts, people with sexual interest in children, and survivors of child
                    sexual abuse.
                </p>
            </div>
            <div className={`${style.threeColumn} ${style.section}`}>
                <PresenterBox
                    image={presenter1}
                    blurb={
                        <div>
                            <b>Elizabeth J. Letourneau, Ph.D.</b>, is Professor, Department of Mental Health, and
                            Director, Moore Center for the Prevention of Child Sexual Abuse, Johns Hopkins Bloomberg
                            School of Public Health. Dr. Letourneau is an internationally recognized expert whose
                            research has examined child sexual abuse prevention, policy, and practice. Her work has
                            influenced the way children with sexual behavior problems are assessed and treated, legal
                            policies that pertain to youth who sexually offend, and international strategies to address
                            child sexual abuse. As the inaugural director of the Moore Center for the Prevention of
                            Child Sexual Abuse, Dr. Letourneau&apos;s efforts now focus on developing, evaluating, and
                            disseminating child sexual abuse prevention interventions. She leads the&nbsp;
                            <i>Help Wanted Prevention Project</i>.
                        </div>
                    }
                />
                <PresenterBox
                    image={presenter2}
                    blurb={
                        <div>
                            <b>Ryan T. Shields, Ph.D.</b>, is Assistant Professor, School of Criminology and Justice
                            Studies, University of Massachusetts Lowell. Dr. Shields is a criminologist with expertise
                            in juvenile justice, punishment, and crime policy. His recent published work has examined
                            policies aimed at youth who have sexually offended, such as juvenile transfer to adult court
                            and registration/notification of youth. Dr. Shields also writes about child sexual abuse
                            prevention and public health approaches to addressing sexual violence. Along with Dr.
                            Letourneau, Dr. Shields is leading the&nbsp;<i>Help Wanted Prevention Project</i>.
                        </div>
                    }
                />
                <PresenterBox
                    image={presenter3}
                    blurb={
                        <div>
                            <b>Amanda E. Ruzicka, M.A.</b>, is Senior Research Associate, Department of Mental Health,
                            and Director of Research Operations, Moore Center for the Prevention of Child Sexual Abuse,
                            Johns Hopkins Bloomberg School of Public Health. Amanda has a background in clinical
                            psychology. Her work centers around the development and testing of prevention interventions
                            targeted at youth and young adults. At the Moore Center, Amanda manages the research arm of
                            the Center. She is currently working on the development and evaluation of three child sexual
                            abuse prevention interventions, including the&nbsp;<i>Help Wanted Prevention Project</i>.
                        </div>
                    }
                />
            </div>
            <div className={style.section}>
                <h2 className={style.title}>Organizational Partners and Funders</h2>
                <div className={style.titleBorder} />
                <ul className={style.list}>
                    <li>
                        <a href="https://3cisd.com" target="_blank" rel="noopener noreferrer">
                            3C Institute
                        </a>
                    </li>
                    <li>
                        <a href="https://www.atsa.com/" target="_blank" rel="noopener noreferrer">
                            Association for the Treatment and Prevention of Sexual Abuse | ATSA
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://publichealth.jhu.edu/moore-center-for-the-prevention-of-child-sexual-abuse"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Moore Center for the Prevention of Child Sexual Abuse
                        </a>
                    </li>
                    <li>
                        <a href="https://www.raliance.org/" target="_blank" rel="noopener noreferrer">
                            RALIANCE: Ending Sexual Violence in One Generation
                        </a>
                    </li>
                    <li>
                        <a href="https://sharperfuture.com/" target="_blank" rel="noopener noreferrer">
                            Sharper Future
                        </a>
                    </li>
                </ul>
            </div>
            <div className={style.section}>
                <h2 className={style.title}>Collaborators</h2>
                <div className={style.titleBorder} />
                <div className={style.threeColumn}>
                    <div>
                        <h4>ATSA Collaborators</h4>
                        <ul className={style.list}>
                            <li>Karen Baker</li>
                            <li>Aniss Benelmouffok</li>
                            <li>Maia Christopher</li>
                            <li>Geri Crisi</li>
                            <li>Gerald Hover</li>
                            <li>Jill Levenson</li>
                            <li>Kieran McCartan</li>
                            <li>Michael Miner</li>
                            <li>Daniel Rothman</li>
                            <li>Joan Tabachnick</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Students and Staff</h4>
                        <ul className={style.list}>
                            <li>Cierra Buckman, research assistant</li>
                            <li>Maegan Ingarm, JHSPH doctoral student</li>
                            <li>Geoff Kahn, JHSPH doctoral student</li>
                            <li>Sarah Murray, JHSPH postdoctoral fellow</li>
                            <li>Evelyn Thorne, JHSPH doctoral student</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Supporters</h4>
                        <ul className={style.list}>
                            <li>&quot;Adam&quot;</li>
                            <li>Anonymous individuals with sexual attraction to children who provided their stories</li>
                            <li>Anonymous survivors of child sexual abuse who provided their stories</li>
                            <li>Luke Malone</li>
                            <li>Virtuous Pedophiles</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
